<template>
    <div>
        <h1 class="mt-2 mb-4">VOG uploaden</h1>

        <b-alert variant="success" :show="success_dismiss_count_down" @dismiss-count-down="countDownChangedSuccess"
            fade>
            <div>
                <b-icon icon="check-circle-fill" font-scale="1" class="mr-3"></b-icon>
                <span class="align-middle">{{ trans('successfully-saved', 253) }}</span>
            </div>
        </b-alert>

        <b-alert variant="danger" :show="unsuccess_dismiss_count_down" @dismiss-count-down="countDownChangedUnsuccess"
            fade>
            <div>
                <b-icon icon="x-circle-fill" font-scale="1" class="mr-3"></b-icon>
                <span class="align-middle">{{ trans('unsuccessfull-save', 253) }}</span>
            </div>
        </b-alert>

        <b-overlay :show="loading" :opacity="0.0">
            <b-card class="mb-3">
                <people-applicants-profile-wizard-document-component :id_document_const_type="9" :maxFilesNumber="1" :itemData="cItemData" :document="cItemData.vog_document" :customRoute="customRoute" />

                <validation-provider v-if="cItemData.vog_document.status != 1" rules="required" #default="{ errors }" name="Verklaring omtrent gedrag">
                    <small v-if="cItemData.vog_document.status != 1 && errors[0] != null" class="text-danger ml-1">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            <template #overlay>
                <div></div>
            </template>
        </b-overlay>
    </div>
</template>

<script>
import axios from "@axios";
import moment from 'moment';
import { datecheck } from "@validations";
import { ValidationProvider } from 'vee-validate';
import PeopleApplicantsProfileWizardDocumentComponent from '@app/People/DataWizard/Step2/Components/PeopleApplicantsProfileWizardDocumentComponent.vue';

export default {
    components: {
        ValidationProvider,
        PeopleApplicantsProfileWizardDocumentComponent,
    },

    props: ["itemData", "showSuccessAlert", "showUnsuccessAlert", "loading"],

    watch: {
        showSuccessAlert(val) {
            this.success_dismiss_count_down = this.dismiss_secs;
        },

        showUnsuccessAlert() {
            this.unsuccess_dismiss_count_down = this.dismiss_secs;
        },
    },

    computed: {
        cItemData: {
            get() { return this.itemData },
            set(value) { this.$emit('update:itemData', value) },
        },
    },

    data() {
        return {
            dismiss_secs: 3,
            success_dismiss_count_down: 0,
            unsuccess_dismiss_count_down: 0,
            customRoute: "people/vog-form/",
        }
    },

    methods: {
        openUpload() {
            document.getElementById('upload_' + this._uid.toString()).click()
        },

        countDownChangedSuccess(success_dismiss_count_down) {
            this.success_dismiss_count_down = success_dismiss_count_down;
        },

        countDownChangedUnsuccess(unsuccess_dismiss_count_down) {
            this.unsuccess_dismiss_count_down = unsuccess_dismiss_count_down;
        },
    },
};
</script>
<style scoped>
.app-local-row-upload {
    padding-left: 0px !important;
    max-width: 40px;
}

.app-local-row {
    padding-left: 0px !important;
    padding-top: 5px;
    max-width: 30px;
}
</style>