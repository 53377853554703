<template>
    <div class="misc-wrapper" style="min-height: 40vh;">
        <div class="misc-inner p-2 p-sm-3">
            <div class="w-100 text-center">
                <div class="d-flex justify-content-center">
                    <img src="@/assets/images/form_done.png" width="400 px" />
                </div>

                <h2 class="mb-1 mt-2">{{ trans('vpw-send-vog-to-validate', 240) }}
                </h2>

                <p class="mb-2">
                    {{ trans('rpw-send-risico-two', 240, {
                        office_name: itemData.office_name, phone: itemData.phone,
                        email: itemData.email
                    }) }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components: {

    },
    props: ["loading", "itemData"],
    data() {
        return {

        }
    }
};
</script>
<style></style>
